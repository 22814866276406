<template>
    <div>
        <zw-input-group v-model="inputVal"
                        name="new_value"
                        :label-prefix="labelPrefix"
                        :readonly="regenerateName"
        ></zw-input-group>
        <b-form-checkbox
            v-model="regenerateName"
            switch
            :unchecked-value="false"
            @change="(val)=>{setRegenerateName(val)}"
        >{{ $t('article.label.regenerate') }}
        </b-form-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    data() {
        return {
            regenerateName: false,
        }
    },
    methods: {
        setRegenerateName(val) {
            if (val) {
                this.inputVal = 'Regenerate'
            } else {
                this.inputVal = ''
            }
        },
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>